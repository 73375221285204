<template>
<v-container >
<h1>SERVICIOS MOBILE</h1>
</v-container>


</template>

<script>

export default {
  name: 'ServiciosViewMobile',
  components: {    
  }
}
</script>