<template>
  <v-container>
    <!-- <AppLayoutHome></AppLayoutHome> -->

    <div v-if="!$vuetify.breakpoint.mobile">

      <ServiciosViewDesktop />
      <AppLayoutFootButtons></AppLayoutFootButtons>
    </div>



    <div v-else>
      <ServiciosViewMobile />
    </div>
  </v-container>


</template>

<script>
// import AppLayoutHome from '@/layouts/AppLayoutHome'
import ServiciosViewDesktop from './ServiciosViewDesktop'
import ServiciosViewMobile from './ServiciosViewMobile'
import AppLayoutFootButtons from '@/layouts/AppLayoutFootButtons'
export default {
  name: 'ServiciosView',
  components: {
    // AppLayoutHome,
    ServiciosViewDesktop,
    ServiciosViewMobile,
    AppLayoutFootButtons

  }
}
</script>